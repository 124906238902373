<template>
  <div></div>
</template>
<script>
import { User } from "@/service";
export default {
  data() {
    return {};
  },
  methods: {
    async getUserInfo() {
      let res = await User.getUserInfo();
      if (res && res.code === 200) {
        let user = JSON.stringify(res.data.items);
        localStorage.setItem("userInfo", user);
        this.$store.commit("setUserInfo", res.data.items)
        this.$router.push("/");
      }
    },
  },
  mounted() {
    let token = this.$route.query.token;
    if (token) {
      this.$store.commit("setToken", token);
      this.getUserInfo();
    }
  },
};
</script>
